<template>
    <ContentContainer title="Changelog">
            <v-row>
                <v-col cols="3">
                    <v-menu 
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                        ref="fromDateMenu"
                        v-model="fromDateMenu">
                        <template v-slot:activator="{on}">
                            <v-text-field 
                                v-model="fromDateString"
                                :rules="[rules.required, fromDateValid]"
                                ref="picker"
                                v-on="on"
                                label="From"
                                prepend-inner-icon="mdi-calendar"
                                dense
                                outlined
                                readonly>
                            </v-text-field>
                        </template>
                        <v-date-picker 
                            v-model="fromDateString"
                            @input="fromDateMenu = false"
                            locale="en-de"
                            no-title
                            scrollable>
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="primary"
                                @click="fromDateMenu = false">
                                OK
                            </v-btn>
                        </v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="3">
                    <v-menu 
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                        ref="toDateMenu"
                        v-model="toDateMenu">
                        <template v-slot:activator="{on}">
                            <v-text-field 
                                v-model="toDateString"
                                :rules="[rules.required, toDateValid]"
                                ref="picker"
                                v-on="on"
                                label="To"
                                prepend-inner-icon="mdi-calendar"
                                dense
                                outlined
                                readonly>
                            </v-text-field>
                        </template>
                        <v-date-picker 
                            v-model="toDateString"
                            @input="toDateMenu = false"
                            locale="en-de"
                            no-title
                            scrollable>
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="primary"
                                @click="toDateMenu = false">
                                OK
                            </v-btn>
                        </v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="3">
                    <v-autocomplete
                        label="Users"
                        v-model="currentFilter.user"
                        return-object
                        item-text="name"
                        prepend-inner-icon="mdi-account"
                        outlined
                        dense
                        clearable
                        :items="users"
                    ></v-autocomplete>
                </v-col>
                <v-col cols="3">
                    <v-text-field
                        v-model="search"
                        outlined
                        dense
                        clearable
                        label="Fulltext search"
                        prepend-inner-icon="mdi-magnify"
                    ></v-text-field>
                </v-col>
            </v-row>
            
            <v-data-table
                :headers="headers"
                :items="items"
                :search="search"
                :loading="loading"
                sort-by="dateTime"
                :sort-desc=true
                class="elevation-1">
                <template v-slot:[`item.userName`]="{ item }">
                    {{ item.firstName }} {{ item.lastName }}
                </template>
                <template v-slot:[`item.dateTime`]="{ item }">
                    {{ dateTimeString(new Date(item.dateTime)) }}
                </template>
            </v-data-table>
    </ContentContainer>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import { changelogService } from "@/services";

import ContentContainer from "@/components/content/ContentContainer";

export default {
    name: "changelog",

    metaInfo: { title: "Changelog" },

    components: {
        ContentContainer
    },

    data: () => ({
        headers: [],
        fromDateString: '',
        toDateString: '',

        currentFilter: {
            user: ''
        },
        search: '',

        rules: {
            required: value => !!value || 'Required'
        },
        fromDateMenu: false,
        toDateMenu: false
    }),

    mounted() {
        // Set Initial Values for Date Filter
        let toDate = new Date();
        this.toDateString = this.dateString(toDate)
        let fromDate = new Date();
        // Initially fetch LogEntries for last 10 Days
        fromDate.setDate(fromDate.getDate() - 10)
        this.fromDateString = this.dateString(fromDate)

        changelogService.get(this.fromDate, this.toDate);
        this.headers = [
            {
                text: "DateTime",
                value: "dateTime"
            },
            {
                text: "User",
                value: "userName",
                filter: this.userFilter
            },
            {
                text: "Type",
                value: "entity"
            },
            {
                text: "Message",
                value: "message"
            }
        ]
    },

    computed: {
        ...mapState("changelog", ["items", "loading"]),
        ...mapGetters("changelog", ["users"]),
        fromDate() {
            return new Date(this.fromDateString)
        },
        toDate() {
            let date = new Date(this.toDateString)
            date.setHours(23)
            date.setMinutes(59)
            date.setSeconds(59)
            return date
        }
    },

    methods: {
        dateString(date) {
            return date.toISOString().split('T')[0]
        },
        dateTimeString(date) {
            let options = {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
                hour: '2-digit',
                minute: '2-digit',
                second: '2-digit'
            }
            return Intl.DateTimeFormat('de-DE', options).format(date)
        },
        // Table Filters
        userFilter(_value, _search, item) {
            let value = item.firstName + ' ' + item.lastName;

            if (!this.currentFilter.user || this.currentFilter.user === value) {
                return true
            }

            return false
        },
        fromDateValid (value) {
            return new Date(value) <= this.toDate || "Must be smaller than 'To Date'"
        },
        toDateValid (value) {
            return this.fromDate <= new Date(value) || "Must be greater than 'From Date'"
        }
    },

    watch: {
        fromDateString: function() {
            if (this.fromDate <= this.toDate) {
                changelogService.get(this.fromDate, this.toDate);
            }
        },
        toDateString: function() {
            if (this.fromDate <= this.toDate) {
                changelogService.get(this.fromDate, this.toDate);
            }
        }
    }
}
</script>