var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ContentContainer",
    { attrs: { title: "Changelog" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { attrs: { cols: "3" } },
            [
              _c(
                "v-menu",
                {
                  ref: "fromDateMenu",
                  attrs: {
                    "close-on-content-click": false,
                    transition: "scale-transition",
                    "offset-y": "",
                    "max-width": "290px",
                    "min-width": "290px",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function (ref) {
                        var on = ref.on
                        return [
                          _c(
                            "v-text-field",
                            _vm._g(
                              {
                                ref: "picker",
                                attrs: {
                                  rules: [
                                    _vm.rules.required,
                                    _vm.fromDateValid,
                                  ],
                                  label: "From",
                                  "prepend-inner-icon": "mdi-calendar",
                                  dense: "",
                                  outlined: "",
                                  readonly: "",
                                },
                                model: {
                                  value: _vm.fromDateString,
                                  callback: function ($$v) {
                                    _vm.fromDateString = $$v
                                  },
                                  expression: "fromDateString",
                                },
                              },
                              on
                            )
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.fromDateMenu,
                    callback: function ($$v) {
                      _vm.fromDateMenu = $$v
                    },
                    expression: "fromDateMenu",
                  },
                },
                [
                  _c(
                    "v-date-picker",
                    {
                      attrs: {
                        locale: "en-de",
                        "no-title": "",
                        scrollable: "",
                      },
                      on: {
                        input: function ($event) {
                          _vm.fromDateMenu = false
                        },
                      },
                      model: {
                        value: _vm.fromDateString,
                        callback: function ($$v) {
                          _vm.fromDateString = $$v
                        },
                        expression: "fromDateString",
                      },
                    },
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { text: "", color: "primary" },
                          on: {
                            click: function ($event) {
                              _vm.fromDateMenu = false
                            },
                          },
                        },
                        [_vm._v(" OK ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "3" } },
            [
              _c(
                "v-menu",
                {
                  ref: "toDateMenu",
                  attrs: {
                    "close-on-content-click": false,
                    transition: "scale-transition",
                    "offset-y": "",
                    "max-width": "290px",
                    "min-width": "290px",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function (ref) {
                        var on = ref.on
                        return [
                          _c(
                            "v-text-field",
                            _vm._g(
                              {
                                ref: "picker",
                                attrs: {
                                  rules: [_vm.rules.required, _vm.toDateValid],
                                  label: "To",
                                  "prepend-inner-icon": "mdi-calendar",
                                  dense: "",
                                  outlined: "",
                                  readonly: "",
                                },
                                model: {
                                  value: _vm.toDateString,
                                  callback: function ($$v) {
                                    _vm.toDateString = $$v
                                  },
                                  expression: "toDateString",
                                },
                              },
                              on
                            )
                          ),
                        ]
                      },
                    },
                  ]),
                  model: {
                    value: _vm.toDateMenu,
                    callback: function ($$v) {
                      _vm.toDateMenu = $$v
                    },
                    expression: "toDateMenu",
                  },
                },
                [
                  _c(
                    "v-date-picker",
                    {
                      attrs: {
                        locale: "en-de",
                        "no-title": "",
                        scrollable: "",
                      },
                      on: {
                        input: function ($event) {
                          _vm.toDateMenu = false
                        },
                      },
                      model: {
                        value: _vm.toDateString,
                        callback: function ($$v) {
                          _vm.toDateString = $$v
                        },
                        expression: "toDateString",
                      },
                    },
                    [
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: { text: "", color: "primary" },
                          on: {
                            click: function ($event) {
                              _vm.toDateMenu = false
                            },
                          },
                        },
                        [_vm._v(" OK ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "3" } },
            [
              _c("v-autocomplete", {
                attrs: {
                  label: "Users",
                  "return-object": "",
                  "item-text": "name",
                  "prepend-inner-icon": "mdi-account",
                  outlined: "",
                  dense: "",
                  clearable: "",
                  items: _vm.users,
                },
                model: {
                  value: _vm.currentFilter.user,
                  callback: function ($$v) {
                    _vm.$set(_vm.currentFilter, "user", $$v)
                  },
                  expression: "currentFilter.user",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { attrs: { cols: "3" } },
            [
              _c("v-text-field", {
                attrs: {
                  outlined: "",
                  dense: "",
                  clearable: "",
                  label: "Fulltext search",
                  "prepend-inner-icon": "mdi-magnify",
                },
                model: {
                  value: _vm.search,
                  callback: function ($$v) {
                    _vm.search = $$v
                  },
                  expression: "search",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-data-table", {
        staticClass: "elevation-1",
        attrs: {
          headers: _vm.headers,
          items: _vm.items,
          search: _vm.search,
          loading: _vm.loading,
          "sort-by": "dateTime",
          "sort-desc": true,
        },
        scopedSlots: _vm._u(
          [
            {
              key: "item.userName",
              fn: function (ref) {
                var item = ref.item
                return [
                  _vm._v(
                    " " +
                      _vm._s(item.firstName) +
                      " " +
                      _vm._s(item.lastName) +
                      " "
                  ),
                ]
              },
            },
            {
              key: "item.dateTime",
              fn: function (ref) {
                var item = ref.item
                return [
                  _vm._v(
                    " " +
                      _vm._s(_vm.dateTimeString(new Date(item.dateTime))) +
                      " "
                  ),
                ]
              },
            },
          ],
          null,
          true
        ),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }